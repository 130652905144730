import { DcPlan, type DcPricing } from "../types/plan";

export async function getPricingData(onSubscribeClicked: (plan: DcPlan) => Promise<void>, fromLandingPage: boolean = false): Promise<DcPricing> {
  const t = useNuxtApp().$i18n.t;
  const response = fromLandingPage ? await useFetch<{ pricing: DcPricing; }>("https://www.docuchat.io/api/pricing") : undefined;
  const dataFromLanding = response?.data.value?.pricing;
  const essential = dataFromLanding?.plans.find(plan => plan.id === DcPlan.Essential);
  const pro = dataFromLanding?.plans.find(plan => plan.id === DcPlan.Pro);
  const enterprise = dataFromLanding?.plans.find(plan => plan.id === DcPlan.Enterprise);

  return {
    note: dataFromLanding?.note ?? "",
    trial: {
      title: dataFromLanding?.trial.title || t("pricing.trial.title"),
      description: dataFromLanding?.trial.description || t("pricing.trial.description"),
      price: dataFromLanding?.trial.price || t("pricing.trial.price"),
      features: dataFromLanding?.trial.features
      || t("pricing.trial.features").split("\n").filter(Boolean),
      button: {
        label: dataFromLanding?.trial.button.label || t("pricing.trial.button.label"),
        click: async () => onSubscribeClicked(DcPlan.Trial),
      },
    },
    plans: [
      {
        id: DcPlan.Essential,
        title: essential?.title || t("pricing.plans.essential.title"),
        description: essential?.description || t("pricing.plans.essential.description"),
        price: essential?.price || t("pricing.plans.essential.price"),
        discountedPrice: essential?.discountedPrice || t("pricing.plans.essential.discountedPrice"),
        yearlyPrice: essential?.yearlyPrice || t("pricing.plans.essential.yearlyPrice"),
        discountedYearlyPrice: essential?.discountedYearlyPrice || t("pricing.plans.essential.discountedYearlyPrice"),
        cycle: essential?.cycle || t("pricing.plans.essential.cycle"),
        badge: essential?.badge || t("pricing.plans.essential.badge"),
        highlight: essential?.highlight || false,
        button: {
          label: essential?.button.label || t("pricing.plans.essential.buttonLabel"),
          click: async () => onSubscribeClicked(DcPlan.Essential),
        },
        features: essential?.features
        || t("pricing.plans.essential.features").split("\n").filter(Boolean).map(f => ({ name: f })),
      },
      {
        id: DcPlan.Pro,
        title: pro?.title || t("pricing.plans.pro.title"),
        description: pro?.description || t("pricing.plans.pro.description"),
        price: pro?.price || t("pricing.plans.pro.price"),
        discountedPrice: pro?.discountedPrice || t("pricing.plans.pro.discountedPrice"),
        yearlyPrice: pro?.yearlyPrice || t("pricing.plans.pro.yearlyPrice"),
        discountedYearlyPrice: pro?.discountedYearlyPrice || t("pricing.plans.pro.discountedYearlyPrice"),
        cycle: pro?.cycle || t("pricing.plans.pro.cycle"),
        highlight: pro?.highlight || true,
        badge: pro?.badge || t("pricing.plans.pro.badge"),
        button: {
          label: pro?.button.label || t("pricing.plans.pro.buttonLabel"),
          click: async () => onSubscribeClicked(DcPlan.Pro),
        },
        features: pro?.features
        || t("pricing.plans.pro.features").split("\n").filter(Boolean).map(f => ({ name: f })),
      },
      {
        id: DcPlan.Enterprise,
        title: enterprise?.title || t("pricing.plans.enterprise.title"),
        description: enterprise?.description || t("pricing.plans.enterprise.description"),
        price: enterprise?.price || t("pricing.plans.enterprise.message"),
        discountedPrice: enterprise?.discountedPrice || "",
        yearlyPrice: enterprise?.yearlyPrice || "",
        discountedYearlyPrice: enterprise?.discountedYearlyPrice || "",
        cycle: enterprise?.cycle || "",
        highlight: enterprise?.highlight || false,
        badge: enterprise?.badge || "",
        button: {
          label: enterprise?.button.label || t("pricing.plans.enterprise.buttonLabel"),
          click: () => onSubscribeClicked(DcPlan.Enterprise),
        },
        features: enterprise?.features
        || t("pricing.plans.enterprise.features").split("\n").filter(Boolean).map(f => ({ name: f })),
      },
    ],
  };
}
