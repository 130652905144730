<script setup lang="ts">
import { DcEndPoints, OrganizationUserRole } from "docuchatcommontypes";
import { DcPlan, DcPlanCycle } from "~/types/plan";
import { UserAction } from "~/types/navigation";

withDefaults(defineProps<{ preventClose?: boolean; }>(), {
  preventClose: true,
});

const { $sentry } = useNuxtApp();
const { t } = useI18n();
const toast = useToast();

const { user, canUseApp, isPlanExpired } = storeToRefs(useUserStore());
const { updatePlan, getBillingPortalUrl, fetchUserData } = useUserStore();
const { signOut } = useAuthentication();
const { isOnPostcheckoutPage, isAllowedToDo } = useNavigation();
const { pricingFromLandingPage } = useRuntimeConfig().public;

// We show the modal if the user cannot use the app and is not on the post checkout page.
const open = computed(() => !isOnPostcheckoutPage() && !canUseApp.value && user.value?.organizationUserRole === OrganizationUserRole.SuperAdmin);

const plans = await getPricingData(handleSubscribeClick, pricingFromLandingPage as boolean);

const billYearly = ref(false);

const header = computed(() => {
  if (isPlanExpired.value) {
    return {
      title: t("pricing.header.expired.title"),
      description: t("pricing.header.expired.description"),
    };
  }
  else {
    if (!user.value?.isPlanChosen) {
      return {
        title: t("pricing.header.getStarted.title"),
        description: t("pricing.header.getStarted.description"),
      };
    }
    else {
      return {
        title: t("pricing.header.updatePlan.title"),
        description: t("pricing.header.updatePlan.description"),
      };
    }
  }
});

async function handleSubscribeClick(plan: DcPlan) {
  if (plan === DcPlan.Enterprise) {
    const subject = t("pricing.enterpriseEmail.subject");
    const mailTo = t("pricing.enterpriseEmail.to");
    const mailToWithSubject = `${mailTo}?subject=${encodeURIComponent(subject)}`;
    window.open(mailToWithSubject, "_blank");
    return;
  }

  sendProcessingNotification();

  try {
    if (plan === DcPlan.Trial) {
      await updatePlan(DcPlan.Trial);
      await fetchUserData();
      toast.add({
        title: t("pricing.toasts.trialStarted.title"),
        description: t("pricing.toasts.trialStarted.description"),
        icon: "i-carbon-lightning",
      });
      throwConfetti();
    }
    else {
      const redirectUrl = await updatePlan(plan, billYearly.value ? DcPlanCycle.Annual : DcPlanCycle.Monthly);
      if (!redirectUrl) {
        throw createError(`No redirect URL was received after subscription request for plan ${plan}`);
      }
      await navigateTo(redirectUrl, { external: true });
    }
  }
  catch (error: any) {
    $sentry.captureError("Error while subscribing to a plan", {
      extra: {
        plan,
        error,
      },
    });
    toast.add({
      title: t("pricing.toasts.error.title"),
      description: error.message || t("pricing.toasts.error.description"),
      icon: "i-carbon-warning",
      color: "red",
    });
  }
}

async function handleSignOut() {
  toast.add({
    title: t("welcome.signOut.message"),
  });
  await signOut();
  await navigateTo("/");
}

async function handleOpenBillingPortal() {
  sendProcessingNotification();

  try {
    const url = await getBillingPortalUrl();
    await navigateTo(url, { external: true });
  }
  catch (error: any) {
    toast.add({
      title: t("pricing.toasts.error.title"),
      description: error.message || t("pricing.toasts.error.description"),
      icon: "i-carbon-warning",
      color: "red",
    });
  }
}

function sendProcessingNotification() {
  toast.add({
    title: t("pricing.toasts.processing.title"),
    description: t("pricing.toasts.processing.description"),
    icon: "i-carbon-time",
  });
}

const deleteAccountLoading = ref(false);

async function handleAccountDelete() {
  deleteAccountLoading.value = true;

  try {
    const endpoint = DcEndPoints.SendDeleteAccountEmail();
    const res = await useApi(endpoint);

    if (!res.success) {
      throw createError(res.message || t("common.genericError"));
    }

    toast.add({
      title: t("user.settings.account.toast.deleteAccount.success"),
      icon: "i-carbon-checkmark",
      timeout: 0,
    });
  }
  catch (error: any) {
    toast.add({
      title: t("user.settings.account.toast.deleteAccount.fail"),
      icon: "i-carbon-warning",
      description: error.message,
      color: "red",
    });
  }

  deleteAccountLoading.value = false;
}

onMounted(async () => {
  // If the user is coming from the landing page with a subscribe query, we open the modal with the plan selected.
  const { subscribe, billYearly: billYearlyRequested } = useRoute().query;
  if (billYearlyRequested)
    billYearly.value = billYearlyRequested === "true";

  if (subscribe)
    await handleSubscribeClick(subscribe as DcPlan);
});
</script>

<template>
  <UModal
    v-model="open"
    :prevent-close="preventClose"
    :ui="{
      width: 'sm:max-w-6xl',
    }"
  >
    <UCard>
      <div class="my-4">
        <h1 class="text-center text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
          {{ header.title }}
        </h1>
        <p class="mt-2 text-center text-base text-gray-500 dark:text-gray-400">
          {{ header.description }}
        </p>
      </div>

      <TheModalSubscribePricingTable
        v-model="billYearly"
        :pricing="plans"
        :can-choose-trial="!user?.isPlanChosen"
        @subscribe="handleSubscribeClick"
      />

      <!-- Footer Actions -->
      <template #footer>
        <div class="flex items-center justify-end gap-4">
          <UPopover v-if="isAllowedToDo(UserAction.DeleteAccount)">
            <UButton
              variant="soft"
              size="xs"
              color="red"
              :disabled="deleteAccountLoading"
            >
              {{ t("pricing.actions.deleteAccount") }}
            </UButton>

            <template #panel>
              <UCard :ui="{ body: { base: 'max-w-sm space-y-3' } }">
                <p class="text-sm">
                  {{ t("base.modal.confirmationDelete.description", { resourceName: t("user.settings.account.deleteAccount.account") }) }}
                  {{ t("user.settings.account.deleteAccount.confirmation.message") }}
                </p>
                <div class="flex items-center justify-start gap-3">
                  <UButton
                    :loading="deleteAccountLoading"
                    color="red"
                    size="xs"
                    @click.prevent="handleAccountDelete"
                  >
                    {{ t("user.settings.account.deleteAccount.buttonLabel") }}
                  </UButton>
                </div>
              </UCard>
            </template>
          </UPopover>

          <UButton
            v-if="isPlanExpired && isAllowedToDo(UserAction.OpenBillingPortal)"
            variant="soft"
            size="xs"
            @click.prevent="handleOpenBillingPortal"
          >
            {{ t("pricing.actions.billingPortal") }}
          </UButton>

          <UButton
            variant="ghost"
            size="xs"
            @click.prevent="handleSignOut"
          >
            {{ t("pricing.actions.signOut") }}
          </UButton>
        </div>
      </template>
    </UCard>
  </UModal>
</template>
