<script lang="ts" setup>
import type { DcPricing } from "~/types/plan";
import { DcPlan } from "~/types/plan";

defineProps<{
  pricing: DcPricing;
  canChooseTrial: boolean;
}>();

const emit = defineEmits<{
  (event: "subscribe", plan: DcPlan): void;
}>();

const billYearly = defineModel<boolean>({ default: false });

const { t } = useI18n();
</script>

<template>
  <div>
    <div class="flex justify-center">
      <UPricingToggle
        v-model="billYearly"
        class="max-w-sm"
        :left="t('pricing.toggle.monthly')"
        :right="t('pricing.toggle.yearly')"
        :ui="{
          marker: 'bg-primary-600 dark:bg-white',
        }"
      />
    </div>

    <UPricingGrid class="mt-5">
      <UPricingCard
        v-for="plan in pricing.plans"
        :key="plan.title"
        :title="plan.title"
        :description="plan.description"
        :price="!billYearly || plan.id === DcPlan.Enterprise ? plan.price : plan.yearlyPrice"
        :discount="!billYearly ? plan.discountedPrice : plan.discountedYearlyPrice"
        :cycle="plan.cycle"
        :badge="plan.badge ? { label: plan.badge } : undefined"
        :highlight="plan.highlight"
        :button="{
          label: plan.button.label,
          click: () => emit('subscribe', plan.id),
        }"
        align="bottom"
        :ui="{
          strategy: 'override',
          features: {
            // @ts-expect-error Nuxt UI handles this fine
            item: {
              label: 'text-gray-500 dark:text-gray-400',
            },
          },
        }"
      >
        <template #features>
          <ul
            v-if="plan.features?.length"
            class="space-y-3 text-sm"
          >
            <li
              v-for="(feature, index) of plan.features"
              :key="index"
              class="flex min-w-0 items-start gap-x-3"
            >
              <UIcon
                name="i-heroicons-check-circle-20-solid"
                class="text-primary size-5 flex-shrink-0"
              />

              <span class="text-gray-600 dark:text-gray-400">{{ feature.name }}<UPopover
                v-if="feature.tooltip"
                mode="hover"
                class="ml-1 inline-flex items-baseline"
              >
                <div>
                  <UIcon
                    name="i-carbon-help-filled"
                    class="relative top-px size-3.5 flex-shrink-0"
                  />
                </div>

                <template #panel>
                  <UCard
                    :ui="{
                      body: {
                        base: 'max-w-sm',
                        padding: 'p-2 sm:p-2',
                      },
                    }"
                  >
                    {{ feature.tooltip }}
                  </UCard>
                </template>
              </UPopover></span>
            </li>
          </ul>
        </template>
      </UPricingCard>
    </UPricingGrid>

    <UPricingCard
      v-if="canChooseTrial"
      class="mt-8"
      :title="pricing.trial.title"
      :description="pricing.trial.description"
      :price="pricing.trial.price"
      :features="pricing.trial.features"
      orientation="horizontal"
      :button="{
        label: pricing.trial.button.label,
        icon: 'i-carbon-bot',
        click: () => emit('subscribe', DcPlan.Trial),
      }"
      :ui="{
        // @ts-expect-error Nuxt UI handles this fine
        amount: {
          price: 'text-xl sm:text-3xl',
        },
      }"
      align="bottom"
    />
  </div>
</template>
